import { render, staticRenderFns } from "./DetalleMatchContratosTabActions.vue?vue&type=template&id=87f3d736&scoped=true&"
import script from "./DetalleMatchContratosTabActions.vue?vue&type=script&lang=js&"
export * from "./DetalleMatchContratosTabActions.vue?vue&type=script&lang=js&"
import style0 from "./DetalleMatchContratosTabActions.vue?vue&type=style&index=0&id=87f3d736&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f3d736",
  null
  
)

export default component.exports